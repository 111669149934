.year-holder {
    width: 100%;
    overflow-x: auto;
}

table.year-table {
    table-layout: fixed;
    border-collapse: collapse;
    width: clamp(660px, 100%, 100%);
    tr {
        border-bottom: 2px solid $grey-dark;
        td:not(.placeholder) {
            padding: 0;
            vertical-align: top;
            &:hover {
                box-shadow: inset 0 0 0 2px black;
                cursor: pointer;
                position: relative;
                z-index: 2;
                &::after {
                    content: attr(label);
                    position: absolute;
                    top: 100%;
                    left: 0;
                    padding: 4px 0;
                    background-color: black;
                    color: $light;
                    font-weight: lighter;
                    width: 100%;
                    font-size: 11px;
                    text-align: center;
                    z-index: 2;
                }
            }
            &:not(:last-child) {
                border-right: 1px solid $grey;
            }
            .event-cal {
                background-color: lightblue;
                margin: 1px 0;
                height: 8px;
                position: relative;
                z-index: 1;
                &.start {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
                &.end {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
                &:not(.end) {
                    &::after {
                        position: absolute;
                        height: 100%;
                        width: 1px;
                        left: 100%;
                        top: 0;
                        background-color: inherit;
                        content: "";
                    }
                }
            }
            .event-cal-placeholder {
                background-color: transparent;
                margin: 1px 0;
                height: 8px;
            }

            &.saturday {
                background-color: lighten(#efefef, 3%);
            }
            &.sunday {
                background-color: lighten(#dfdfdf, 5%);
            }
            &.today {
                background-color: rgba($accent, 0.1);
            }
        }
    }
}

.year-modal-box {
    padding: 16px;
    border-radius: 4px;
    margin: 10px 0;
    min-width: 300px;

    h3 {
        margin: 0;
    }
    a {
        display: block;
        width: 100%;
        text-align: center;
        margin: 7px 0 0 0;
        padding: 4px 10px;
        background-color: rgba(255, 255, 255, 0.2);
    }
}