.dash-box {
    width: calc(100% - 20px);
    margin: 10px;
    background-color: $light;
    border-radius: 6px;
    border-left: 3px solid $accent;
    box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.08);
    position: relative;
    overflow: hidden;
    padding: 18px 18px 16px 12px;
    cursor: pointer;
    .icon {
        opacity: 0.15;
        position: absolute;
        font-size: 75px;
        line-height: 75px;
        bottom: -28px;
        right: -15px;
        transition: .2s ease;
    }
    .content {
        .marked {
            font-size: 22px;
            font-weight: bolder;
        }
    }
    .title {
        font-weight: 14px;
        margin-top: 4px;
        opacity: 0.8;
        font-weight: lighter;
    }
    &:hover {
        box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.1);
        .icon {
            transform: scale(1.2);
        }
    }
}

.pagination {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    a {
        padding: 10px 14px;
        border: 1px solid $grey-dark;
        border-radius: 3px;
        margin: 10px;
        text-decoration: none;
        color: $dark;
        cursor: pointer;
        &.active {
            background-color: $accent-light;
            border-color: $accent;
        }
    }
}