.formfield {
    position: relative;
    margin: 15px 0;
    label {
        position: absolute;
        top: 10px;
        left: 15px;
        font-size: 16px;
        opacity: 0.6;
        transition: .2s ease;
        padding: 3px 5px;
        background-color: $light;
        cursor: text;
        user-select: none;
    }
    input, select, textarea {
        outline: none;
        border: none;
        border: 2px solid lighten($dark, 80%);
        font-size: 16px;
        padding: 10px 15px;
        border-radius: 5px;
        background-color: transparent;
        display: block;
        width: 100%;
        &:focus + label, &:active + label, &:valid + label, &:focus-within + label {
            top: -8px;
            font-size: 10px;
            opacity: 1;
        }
        &:focus, &:active {
            border-color: $accent;
            & + label {
                color: $accent;
            }
        }
    }

    input[type="checkbox"] {
        position: absolute;
        left: 0;
        visibility: hidden;
        &::before {
            visibility: visible;
            content: "";
            height: 20px;
            width: 40px;
            background-color: $grey-dark;
            position: absolute;
            left: 0;
            z-index: 1;
            opacity: 1;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 10px;
            transition: .2s ease;
        }
        &::after {
            visibility: visible;
            content: "";
            position: absolute;
            left: 2px;
            top: 50%;
            transform: translateY(-50%);
            transition: .2s ease;
            height: 16px;
            width: 16px;
            border-radius: 50%;
            z-index: 2;
            background-color: $light;
            transition: .2s ease;
        }
        &:checked {
            &::before {
                background-color: $accent;
            }
            &::after {
                left: 20px;
            }
        }

        &+label {
            position: static;
            font-size: 14px;
            padding: 8px 12px 8px 55px;
            background-color: transparent;
            margin: 0;
        }

        &:hover, &+label:hover {
            cursor: pointer;
        }
    }
    &.underline {
        padding-bottom: 12px;
        margin-bottom: 15px;
        border-bottom: 1px solid $grey-dark;
    }


    input[type='file'] {
        visibility: hidden;
        position: relative;
        &:before {
            content: "Datei hier ablegen";
            position: absolute;
            visibility: visible;
            outline: none;
            border: none;
            border: 2px solid lighten($dark, 80%);
            font-size: 16px;
            padding: 10px 15px;
            border-radius: 5px;
            background-color: transparent;
            display: block;
            width: calc(100% - 34px);
            top: 0px;
            left: 0;
            cursor: pointer;
        }
        &::after {
            content: none;
            position: fixed;
            visibility: visible;
            top: 0px;
            left: 0px;
            height: calc(100% - 0px);
            width: calc(100% - 0px);
            border-radius: 20px;
            border: 0px solid $dark;
            background-color: rgba($light, 0.3);
            z-index: 96;
            backdrop-filter: blur(10px);
            font-size: clamp(1em, 5vw, 2em);
            font-size: 5vw;
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgba($dark, 0.6);
        }
    }

    &.file-hover {
        input[type='file']::after {
            content: "Loslassen zum hochladen";
        }
    }
}