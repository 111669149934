.flex-grid {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    &>div {
        flex: 1;
        min-width: 200px;
        &.medium {
            min-width: 400px;
            @media (max-width: 460px) {
                min-width: 100%;
            }
        }
        &.large {
            min-width: 600px;
        }
    }
    &>div.flex-1 {
        flex: 1;
    }
    &>div.flex-2 {
        flex: 2;
    }
    &>div.flex-3 {
        flex: 3;
    }
    &>div.flex-4 {
        flex: 4;
    }
    &>div.flex-5 {
        flex: 5;
    }
}

.flex {
    width: 100%;
    display: flex;
    align-items: center;
    &.flex-between {
        justify-content: space-between;
    }
    &.flex-inline {
        display: inline-flex;
        width: auto;
    }
    &.flex-spacing {
        &>* {
            margin: 10px;
            &:first-child { margin-left: 0; }
            &:last-child { margin-right: 0; }
        }
    }
}