.editablecomponent {

    .preview {

        display: block;
        &.hidden {
            display: none;
        }

        .previewText {}

        .previewIcon {
            display: none;
            font-size: 18px;
            padding-left: 10px;
            cursor: pointer;
        }

        &:hover .previewIcon {
            display: inline-block;
        }

    }

    .edit {

        display: flex;
        align-items: flex-end;

        &.hidden {
            display: none;
        }

        .editText {
            width:100%;
            padding: 0;
            border: none;
            margin: 0;
            outline: none;
            border-bottom: 1px solid $accent;
        }

        .editIcon {
            color: $dark;
            padding: 8px 12px 4px 12px;
            border-radius: 0;
            border: 0;
            border-bottom: 1px solid $accent;
            background-color: transparent;
            cursor: pointer;
            text-decoration: none;
            outline: none;
            font-weight: bolder;
            font-size: 18px;
        }

    }

}


/**
OPTONAL CSS
**/
.editablecomponent {

    &.report-title .txt {

        font-size: 2em;
        font-weight: bold;

    }

}