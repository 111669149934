#loginscreen {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 60px 0;

    .loginbox {

        width: 100%;
        max-width: 400px;

        box-shadow: 0 0 7px 0 rgba($dark, 0.5);
        padding: 30px 20px;

        position: relative;

        img {
            width: 200px;
        }

        .error {
            padding: 15px;
            border-left: 3px solid $accent;
            margin: 10px 0 30px;
        }

        &.loading {
            &::before {
                height: 100%;
                width: 100%;
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba($light, 0.8);
                z-index: 12;
            }
            &::after {
                height: 100%;
                width: 100%;
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 150px;
                width: 150px;
                border: 3px solid transparent;
                border-top-color: $accent;
                border-radius: 50%;
                animation: loginloader 1s linear infinite;
                z-index: 15;
            }
        }

    }

}

@keyframes loginloader {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}