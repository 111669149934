.sidebar {
    width: 250px;
    margin-right: 50px;
    margin-top: 50px;
    display: inline-block;

    @media only screen and (max-width: 850px) {
        width: 220px;
    }

    @media only screen and (max-width: 770px) {
        width: 190px;
    }

    a {
        display: block;
        margin: 8px 0;
        padding: 13px 12px 13px 20px;
        border-radius: 0 20px 20px 0;
        font-weight: bold;
        font-size: 12px;
        text-decoration: none;
        color: $dark;
        position: relative;
        transform: translateY(-50%);
        transition: .2s ease;
        .icon {
            position: absolute;
            top: 50%;
            left: 0;
            opacity: 0;
            transform: translateY(-50%);
            transition: .1s ease;
            font-size: 17px;
            line-height: 17px;
        }
        &.active {
            color: $accent;
            background-color: $accent-light;
        }
        &:not(.active):hover {
            background-color: $grey;
        }
        span {
            transition: transform .1s ease;
            display: block;
        }
        &:hover, &.active {
            span {
                transform: translateX(22px);
            }
            .icon {
                opacity: 1;
                left: 20px
            }
        }
    }

    @media only screen and (max-width: 660px) {
        display: block;
        margin: 0;
        width: 100%;
        overflow-x: auto;
        padding: 20px;

        white-space: nowrap;

        .sidebaritem {
            display: inline-block;
        }

        a {
            display: inline-block;
            margin: 0 8px;
            padding: 13px 12px 13px 12px;
            border-radius: 20px;
            font-weight: bold;
            font-size: 12px;
            text-decoration: none;
            color: $dark;
            transform: none;
            &.active {
                color: $accent;
                background-color: $accent-light;
            }
            &:not(.active):hover {
                background-color: lighten($dark, 82%);
            }
            .icon {
                display: none;
            }
            &:hover, &.active {
                span {
                    transform: none;
                }
            }
        }
    }

}