nav {

    position: fixed;
    z-index: 75;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 0 rgba(2, 2, 2, 0.5);
    background-color: $light;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 20px;

    .branding {
        height: 32px;
        img {
            height: 100%;
        }
    }

    .content {
        height: 100%;

        .links {
            height: 100%;
            
            display: flex;
            justify-content: flex-end;
            align-items: center;

            a {
                color: $dark;
                text-decoration: none;
                font-weight: bold;
                font-size: 16px;
                display: flex;
                align-items: center;
                height: 50px;
                padding: 0 14px;
                position: relative;

                &.active {
                    color: $accent;
                }
            }

        }

    }

    .profile {
        .profilePicture {
            height: 32px;
            width: 32px;
            border-radius: 50%;
            background-position: center;
            background-size: contain;
            cursor: pointer;
            position: relative;

            .profileContent {
                &:not(.open) { 
                    height: 0;
                    width: 0;
                }
                transition: .2s ease;
                overflow: hidden;
                border-radius: 5px;
                box-shadow: 0 0 4px 0 rgba(2, 2, 2, 0.5);
                position: absolute;
                top: calc(100% + 10px);
                right: 0;
                width: 200px;
                height: 320px;
                background-color: $light;
                z-index: 80;
                user-select: none;
                .header {
                    height: 80px;
                    width: 100%;
                    padding: 0 20px;
                    display: flex;
                    align-items: center;
                    .name {
                        font-weight: bolder;
                        font-size: 18px;
                        display: block;
                    }
                    .mail {
                        font-weight: lighter;
                        opacity: 0.8;
                        font-size: 10px;
                    }
                }
                .sites {
                    display: block;
                    height: calc(320px - 130px);
                    overflow-y: auto;
                    .site {
                        white-space: nowrap;
                        padding: 15px 20px;
                        font-size: 14px;
                        &:hover, &.active {
                            background-color: $grey;
                        }
                    }
                }
                .logout {
                    display: block;
                    height: 49px;
                    border-top: 1px solid #ddd;
                    padding: 0 20px;
                    a {
                        color: $accent;
                        text-decoration: none;
                        font-weight: lighter;
                        font-size: 16px;
                        display: flex;
                        align-items: center;
                        height: 100%;
                    }
                }
            }
        }
    }

    .mobile {
        display: none;
    }

    @media only screen and (max-width: 600px){
        .branding {
            width: 100%;
        }
        .profile {
            margin-right: 20px;
        }
        .mobile {
            height: 18px;
            width: 32px;
            position: relative;
            display: flex;
            align-items: center;
            &::before, &::after {
                content: "";
                height: 2px; 
                width: 100%;
                background-color: $dark;
                position: absolute;
                transform-origin: center;
                left: 0;
                transition: .2s ease;
            }
            &::before {
                top: 0;
            }
            &::after {
                bottom: 0;
            }
            span {
                height: 2px; 
                width: 100%;
                background-color: $dark;
                transition: .2s ease;
            }
        }

        .content {
            position: fixed;
            top: 50px;
            left: 100%;
            height: calc(100% - 50px);
            width: 100%;
            background-color: $light;
            z-index: 60;
            transition: .2s ease;
            .links {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                a {
                    padding: 20px 14px;
                    margin: 10px 0;
                }
            }
        }

        &.open {

            .content {
                left: 0;
            }

            .mobile {
                &::before {
                    top: calc(50% - 1px);
                    transform: rotate(45deg);
                }
                &::after {
                    bottom: calc(50% - 1px);
                    transform: rotate(-45deg);
                }
                span {
                    width: 0;
                }
            }
        }
    }

}