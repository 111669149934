.modal {
    display: none;
    &.loading {
        .modal-window{
            &::before {
                content: "";
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 10;
                backdrop-filter: blur(2px);
                background-color: rgba($light, 0.9);
            }
            &::after {
                position: absolute;
                content: "";
                height: 70px;
                width: 70px;
                border-radius: 50%;
                top: 50%;
                left: 50%;
                z-index: 11;
                transform: translate(-50%, -50%);
                border: 5px solid transparent;
                border-top-color: $grey-dark;
                animation: modalloading 1s linear infinite;
            }
        }
    }
    &.open {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 40px 10px 20px;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background-color: rgba(0,0,0,0.65);
        z-index: 97;
        .modal-window {
            overflow-y: auto;
            min-height: 200px;
            min-width: 300px;
            max-width: 100% !important;
            max-height: 100% !important;
            background-color: $light;
            border-radius: 10px;
            position: relative;
            .modal-closer {
                position: absolute;
                top: 10px;
                right: 10px;
                height: 30px;
                width: 30px;
                cursor: pointer;
                span {
                    &::before, &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 0;
                        width: 100%;
                        height: 2px;
                        background-color: $dark;
                    }
                    &::before { transform: rotate(45deg); }
                    &::after { transform: rotate(-45deg); }
                }
            }
            .modal-content {
                padding: 10px 20px;
            }
        }
    }
}

@keyframes modalloading {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}