.pageloader {

    width: 100%;
    height: 200px;

    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
        content: "";
        height: 80px;
        width: 80px;
        border-radius: 50%;
        border: 5px solid transparent;
        border-top-color: $grey;
        animation: pageloader 1s infinite linear;
    }

}

.pageloader-image {
    width: 100%;
    height: 200px;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
        height: 180px;
    }
}

@keyframes pageloader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.loading-spinner {
    position: relative;
    height: 80px;
    width: 80px;
    border: 2px solid $accent;
    border-radius: 50%;
    &::before, &::after {
        content: "";
        position: absolute;
        height: 84px;
        width: 42px;
        top: -3px;
        background-color: $light;
    }
    &::before {
        border-radius: 42px 0 0 42px;
        left: -3px;
        transform-origin: right;
        animation: 1.2s loadspinner ease infinite;
        animation-delay: .2s;
    }
    &::after {
        border-radius: 0 42px 42px 0;
        right: -3px;
        transform-origin: left;
        animation: 1.2s loadspinner ease infinite;
    }
    @keyframes loadspinner {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
}