code {
    font-size: 14px;
    font-family: monospace;
    padding: 3px 5px;
    border-radius: 3px;
    background-color: darken($grey, 3%);
    &.block {
        display: block;
        padding: 10px 8px;
        line-height: 1.8;
        font-size: 14px;
        width: 100%;
        overflow-x: auto;
    }
}

.docs {
    li {
        line-height: 1.5;
    }
    a {
        color: $accent;
        &:hover {
            text-decoration: underline;
        }
    }
    hr {
        margin: 40px 0 20px 0;
        border: 1px solid $grey;
    }
}