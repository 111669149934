.btn {
    color: $light;
    padding: 14px 22px;
    border-radius: 5px;
    border: 3px solid $accent;
    background-color: $accent;
    cursor: pointer;
    text-decoration: none;
    outline: none;
    font-weight: bolder;
    font-size: 16px;

    &.block {
        display: block;
        width: 100%;
    }

    &.outline {
        color: $accent;
        background-color: transparent;
    }

    &.small {
        padding: 7px 12px;
    }

    &.simple {
        background-color: $grey-dark;
        color: $dark;
        display: block;
        text-align: center;
        padding: 7px 20px;
        font-weight: bold;
        border-radius: 3px;
        margin: 10px 0;
        border: none;
    }

    &.add {
        padding: 5px 10px 5px 34px;
        border-radius: 30px;
        font-size: 14px;
        position: relative;
        &::before {
            position: absolute;
            left: 10px;
            content: "";
            width: 14px;
            height: 2px;
            top: calc(50% - 1px);
            background-color: $light;
        }
        &::after {
            position: absolute;
            left: 16px;
            content: "";
            width: 2px;
            height: 14px;
            top: calc(50% - 7px);
            background-color: $light;
        }
    }
}

.toggle2 {
    height: 38px;
    width: 90px;
    border-radius: 20px;
    box-shadow: 0 1px 4px 0 rgba($dark, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        cursor: pointer;
        &.active {
            color: $accent;
        }
    }
}