.tooltip {

    cursor: pointer;
    position: relative;

    .tooltip-content {
        display: none;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.185);
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 10px;
        padding: 10px;
        background-color: $light;
        width: 160px;
        z-index: 4;
        &::after {
            content: "";
            position: absolute;
            height: 12px;
            width: 12px;
            bottom: -6px;
            left: calc(50% - 6px);
            transform: rotate(45deg);
            background-color: $light;
        }
    }

    &:hover .tooltip-content {
        display: flex;
    }

}