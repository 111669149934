.table {

    width: 100%;
    overflow-x: auto;

    table {
        min-width: 100%;
        border-collapse: collapse;

        thead {
            font-weight: bolder;
            tr {
                background-color: $grey;
            }
        }

        tr {
            border-bottom: 2px solid $grey-dark;

            &:nth-child(even){
                background-color: $grey;
            }

            td {
                padding: 12px 16px;

                &:first-of-type {
                    font-weight: bolder;
                }

                .duration {
                    opacity: 0.7;
                    display: block;
                    font-size: 10px;
                    padding: 0 10px 3px;
                    text-align: center;
                    border-bottom: 1px solid $dark;
                }

                a {
                    color: inherit;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

        }
    }

}