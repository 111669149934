.report-state {
    font-size: 10px;
    font-weight: bolder;
    padding: 6px 12px;
    border-radius: 20px;
    &.state-0 {
        background-color: rgb(227, 157, 36);
        color: $light;
    }
    &.state-1 {
        background-color: rgb(24, 143, 5);
        color: $light;
    }
    &.state-2 {
        background-color: rgb(12, 128, 103);
        color: $light;
    }
    &.state-3 {
        background-color: rgb(221, 40, 40);
        color: $light;
    }
}

.kw_light {
    position: relative;
    padding-left: 30px;
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: grey;
    }
    &.red::after {
        background-color: rgb(221, 40, 40);
        opacity: 0.1;
    }
    &.orange::after {
        background-color: rgb(227, 157, 36);
        opacity: 0.4;
    }
    &.green::after {
        background-color: rgb(15, 113, 0);
        opacity: 0.7;
    }
    &.lightgreen::after {
        background-color: rgb(7, 223, 0);
    }
}