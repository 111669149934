.alert {
    padding: 20px;
    margin: 10px 0;
    width: 100%;
    border-radius: 7px;
    border-left: 4px solid rgb(54, 162, 235);
    background-color: rgba(54, 162, 235, 0.2);

    &.warning {
        border-left: 4px solid rgb(255, 159, 64);
        background-color: rgba(255, 159, 64, 0.2);
    }
    &.error {
        border-left: 4px solid rgb(255, 99, 132);
        background-color: rgba(255, 99, 132, 0.2);
    }
    &.success {
        border-left: 4px solid rgb(79, 223, 71);
        background-color: rgba(79, 223, 71, 0.2);
    }
}