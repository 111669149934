#root {
    display: flex;
    align-items: flex-start;
    @media only screen and (max-width: 660px) {
        display: block;
    }
}
#main-content {
    min-height: 500px;
    width: calc(100% - 300px);
    display: inline-block;
    padding: 50px 20px 50px 0;
    max-width: 1000px;
    margin: auto;

    &.popup {
        width: 100%;
        padding: 50px 20px;
    }

    @media only screen and (max-width: 850px) {
        width: calc(100% - 270px);
    }

    @media only screen and (max-width: 770px) {
        width: calc(100% - 240px);
    }

    @media only screen and (max-width: 660px) {
        width: 100%;
        padding: 50px 20px 50px 20px;
    }

}

#main-content-out {

    min-height: 500px;
    width: 100%;
    padding: 50px 20px;
    max-width: 1000px;
    margin: auto;

}