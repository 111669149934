* {
    box-sizing: border-box;
}

html, body { margin: 0; padding: 0; }

body {
    background-color: $body;
    font-family: Arial, Helvetica, sans-serif;
    overflow-x: hidden;
    padding-top: 50px;
    color: $dark;
}

@import './general/navigation.scss';
@import './general/sidebar.scss';
@import './general/maincontent.scss';
@import './general/login.scss';
@import './general/buttons.scss';
@import './general/grid.scss';
@import './general/table.scss';
@import './general/pageloader.scss';
@import './general/labels.scss';
@import './general/modal.scss';
@import './general/editalcomponent.scss';
@import './general/tooltip.scss';
@import './general/date.scss';
@import './general/infos.scss';
@import './general/forms.scss';

@import './pages/events.scss';
@import './pages/dashboard.scss';
@import './pages/goals.scss';
@import './pages/docs.scss';
@import './pages/year.scss';
@import './pages/report.scss';

#mainpageloader {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        height: 250px;
        width: 250px;
        border: 10px solid transparent;
        border-top-color: $accent;
        border-radius: 50%;
        content: "";
        animation: mainpageloader infinite 1s linear;
    }
}
#mainpageloader-image {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 100%;
        max-width: 600px;
    }
}

@keyframes mainpageloader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

a {
    color: inherit;
    text-decoration: inherit;
    &:hover {
        color: inherit;
        text-decoration: inherit;
    }
}

p a {
    color: $accent;
    text-decoration: none;
    &:hover {
        color: $accent;
        text-decoration: underline;
    }
}

p {
    line-height: 1.8;
}