.event {

    margin: 10px 0;
    width: calc(100% - 70px);
    border-radius: 0 14px 14px 0;
    background-color: $grey;

    margin-left: 70px;
    position: relative;
    min-height: 100px;
    .date {
        position: absolute;
        top: 0;
        right: 100%;
        border-radius: 14px 0 0 14px;
        background-color: $dark;
        color: $light;
        font-weight: lighter;
        font-size: 14px;
        padding: 27px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 56px;
        height: 100%;
        flex-direction: column;
        span {
            display: block;
            text-align: center;
            &.line1 {
                font-size: 16px;
            }
            &.line2 {
                font-weight: bold;
                font-size: 17px;
            }
        }
    }

    &.loading {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            border: 2px solid transparent;
            border-top-color: $grey-dark;
            animation: eventSpinnerLoading 1s infinite linear;
        }
    }

    &.insta-post, &.twitter-post {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        &>div {
            width: 100%;
            padding: 20px;
            img {
                height: 180px;
                transition: .2s ease;
                float: right;
                @media only screen and (max-width: 560px) {
                    width: 50px;
                }
            }
            h3 {
                font-size: 28px;
                margin: 0;
                cursor: pointer;
                user-select: none;
            }
            span {
                opacity: 0.8;
                font-size: 12px;
            }
            p {
                line-height: 1.6;
                font-size: 14px;
            }
        }
        p {
            a.openWin {
                color: $dark;
                text-decoration: none;
                font-weight: bold;
                padding: 7px 14px;
                background-color: $grey-dark;
                border-radius: 6px;
            }
            small {
                opacity: 0.8;
            }
        }
        .cont {
            overflow: hidden;
            max-height: 1000px;
            transition: .2s ease;
        }
        &:not(.active) {
            img {
                height: 50px;
                float: right;
            }
            .cont {
                max-height: 0;
            }
        }
    }

}

@keyframes eventSpinnerLoading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}