.goalitem {
    position: relative;
    margin: 40px 0 20px 0;
    border-radius: 8px;
    border: 4px solid $light;
    box-shadow: 0 0 15px 0 rgba(100,100,100,0.2);
    padding: 20px;

    h2, p {
        padding: 0;
        margin: 0;
    }

    .markasdone {
        position: absolute;
        left: -4px;
        bottom: calc(100% + 4px);
        font-size: 12px;
        background-color: $light;
        opacity: 0;
        cursor: pointer;
        border: 1px solid $grey;
        display: flex;
        justify-content: flex-start;
        &>div {
            height: 30px;
            padding: 0 20px;
            display: flex;
            align-items: center;
            &:not(:last-child) {
                border-right: 1px solid $grey;
            }
            &:hover {
                background-color: $grey;
            }
        }
    }

    .note-text {
        font-size: 14px;
        margin: 10px 0;
    }

    .progress {
        margin: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .label-0 {
            font-size: 12px;
            color: $accent;
            margin-right: 10px;
        }
        .bar-holder {
            width: 100%;
            border-radius: 3px;
            height: 6px;
            background-color: $grey;
            .bar {
                border-radius: 3px;
                height: 100%;
                width: 0;
                background-color: $accent;
            }
        }
        .label-100 {
            font-size: 12px;
            color: $grey-dark;
            margin-left: 10px;
        }
    }

    &.done {
        border-color: mediumseagreen;
        .progress {
            .label-0, .label-100 {
                color: mediumseagreen;
            }
            .bar-holder .bar {
                background-color: mediumseagreen;
            }
        }
    }

    &:hover {
        // &:not(.done) {
            border-top-left-radius: 0;
            .markasdone {
                opacity: 1;
            }
        // }
    }

}

.mini-goal {
    border: 1px solid $grey-dark;
    border-radius: 4px;
    padding: 15px 10px;
    margin: 8px 0;
    .g-title {
        font-weight: bold;
        font-size: 18px;
        display: block;
    }
    .g-desc {
        font-weight: light;
        font-size: 14px;
        padding: 4px 0 0 0;
        display: block;
    }
    .finished {
        display: block;
        font-size: 12px;
        line-height: 12px;
        font-weight: bold;
        svg {
            transform: translateY(1px);
        }
        color: seagreen;
        padding: 6px 0 0 0;
    }
}